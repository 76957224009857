import { EnumToArray } from '../../../utils/enumToArray';

enum Kind {
  Default = 'default',
  Notify = 'notify',
}

export const PTZCardButtonConfigList = {
  Kind: EnumToArray([Kind]),
};

export const PTZCardButtonConfig = {
  Kind,
};
