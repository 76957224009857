ptz-card-button {
 .ptz-card-button {
    border: none;
    display: flex;
    cursor: pointer;
    align-items: center;
    gap: $petz-spacing-2xs;
    justify-content: center;
    width: $petz-spacing-2xl;
    height: $petz-spacing-2xl;
    padding: $petz-spacing-sm 0;
    border-radius: $petz-rounded-max;
    background-color: $petz-color-neutral-black;

    &[disabled] {
      background-color: $petz-color-neutral-light;
    }

    &-check {
      padding: 0.6rem 0;
      background-color: $petz-color-neutral-white;
    }
  }
}
