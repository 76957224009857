import { Component, Prop, Host, h, Event, EventEmitter, Watch } from '@stencil/core';
import { PTZCardButtonTypes } from './types/ptz-card-button.types';
import { PTZCardButtonConfig } from './types/ptz-card-button.enums';
import lottie from 'lottie-web/build/player/lottie_light';
import { getStaticBaseURL } from '../../utils/environment';

@Component({
  tag: `ptz-card-button`,
  styleUrl: 'ptz-card-button.scss',
  shadow: false
})

export class PTZCardButton {
  /** Desabilita o botão */
  @Prop() disabled?: boolean = false;

  /** Estado de carregando no botão */
  @Prop() loading?: boolean = false;

  /** Estado de sucesso do botão */
  @Prop() check?: boolean = false;

  /** Aria label para o botão */
  @Prop() ariaLabel: string;

  /** Tipo de botão */
  @Prop() kind?: PTZCardButtonTypes.Kind = PTZCardButtonConfig.Kind.Default;

  /** Evento que dispara no click do botão */
  @Event({
    eventName: 'clickButton',
    composed: true,
  }) clickButton: EventEmitter<{event: MouseEvent}>;

  private elementRef: HTMLButtonElement;

  private handleClickButton(event: MouseEvent) {
    event.preventDefault();
    this.clickButton.emit({event});
  }

  @Watch('check')
  watchCheckHandler(newCheckValue: boolean) {
    if (newCheckValue) {
      lottie.loadAnimation({
        name: 'checkLottie',
        container: this.elementRef,
        path: `${getStaticBaseURL(window.location.host)}/platforms/img/product/superapp/lottie/check_on.json`,
        renderer: 'svg',
        loop: false
      });
    }

    if (!newCheckValue) {
      lottie.destroy('checkLottie')
    }
  }

  componentDidLoad() {
    if (this.check) {
      lottie.loadAnimation({
        name: 'checkLottie',
        container: this.elementRef,
        path: `${getStaticBaseURL(window.location.host)}/platforms/img/product/superapp/lottie/check_on.json`,
        renderer: 'svg',
        loop: false
      });
    }
  }

  render() {
    const classButton = `
      ptz-card-button
      ${!this.disabled && this.loading ? `ptz-card-button-loading` : ''}
      ${this.check ? `ptz-card-button-check` : ''}
    `;

    const renderIconNotify = this.kind === PTZCardButtonConfig.Kind.Notify ? (
      <ptz-icon
        size='md'
        variant='line'
        color='neutral-white'
        name='petz-notification'
      />
    ) : '';

    const renderIconDefault = this.kind === PTZCardButtonConfig.Kind.Default && !this.check && !this.loading ? (
      <ptz-icon
        size='md'
        name='plus'
        variant='line'
        color={this.disabled ? 'neutral-dark' : 'neutral-white'}
      />
    ) : '';

    const renderSpinnerLoading = !this.disabled && this.loading ? (
      <ptz-spinner
        size="md"
        appearance={'dark'}
        class={'ptz-card-button-loader'}
      ></ptz-spinner>
    ) : '';

    return (
      <Host>
        <button
          class={classButton}
          disabled={this.disabled}
          aria-label={this.ariaLabel}
          onClick={(event) => this.handleClickButton(event)}
          ref={(el: HTMLButtonElement) => (this.elementRef = el)}
        >
          {renderIconNotify}
          {renderIconDefault}
          {renderSpinnerLoading}
        </button>
      </Host>
    );
  }
}
